import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModDesescalada from "../../../components/Chronology/Modules/ModDesescalada";
import ModGraphFull from "../../../components/Chronology/Modules/ModGraphFull";
import { StrongWhite, StrongGold } from "../../../components/Textstyles/Styles";

import Day78 from "./dia-30-may";
import Day79 from "./dia-31-may";
import Day80 from "./dia-01-jun";
import Day81 from "./dia-02-jun";
import Day82 from "./dia-03-jun";
import Day83 from "./dia-04-jun";
import Day84 from "./dia-05-jun";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <ModAlertBanner color="brand04">
        El Reino Unido supera los 40.000 fallecidos con covid-19
      </ModAlertBanner>
      <ModDesescalada />
      <Day84 {...props} />
      <ModMegaBanner bg="brand05">
        El Pleno del Congreso ha dado luz verde a la{" "}
        <StrongGold>última prórroga</StrongGold> del estado de alarma, que
        llevará las restricciones hasta el próximo{" "}
        <StrongWhite>21 de junio</StrongWhite>.
      </ModMegaBanner>

      <Day83 {...props} />
      <Day82 {...props} />
      <Day81 {...props} />
      <Day80 {...props} />
      <ModGraphFull
        name="mapa-fases-transicion-0106"
        alt="mapa transicion fases españa"
      />
      <Day79 {...props} />
      <Day78 {...props} />
    </LayoutWeek>
  );
};

export default Week;
